<template>
    <div>

    <el-main style=" padding-top: 5px;padding-bottom: initial  ">
        <el-row>
            <el-col :lg="24">
                <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                    <el-row>
                        <el-col :lg="24" style="line-height: 27px;">
                            <strong style=" font-size: 16px; ">当前：角色管理</strong>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col :lg="24">
                <el-form>
                    <el-row>
                        <el-col :lg="2" :sm="12" :md="8">
                            <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" @click="addRole">新增添加</el-button>
                        </el-col>
                        <el-col :lg="2" :sm="12" :md="8">
                            <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" @click="syncRole">同步权限</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :lg="24">
                <vxe-grid
                          border
                          resizable
                          keep-source
                          ref="xGrid"
                          height="620"
                          highlight-hover-row
                          :data="tableDataMain"
                          :columns="tableColumn">
                        <template #roleName_default="{row}">
                            <el-tag size="mini" type="success" v-for="(rName,i) in row.roleName" :key="i">{{rName.name}}</el-tag>
                        </template>
                        <template #operate="{row}">
                            <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="editRowEvent(row)"></vxe-button>
                            <vxe-button icon="el-icon-setting" title="分配权限" circle @click="openAssigningRole(row)" ></vxe-button>
                            <vxe-button icon="el-icon-delete" title="删除" circle @click="removeEvent(row)"></vxe-button>
                        </template>
                        <template #pager>
                            <vxe-pager
                                    :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                    :current-page.sync="tablePage.currentPage"
                                    :page-size.sync="tablePage.pageSize"
                                    :total="tablePage.total"
                                    @page-change="handlePageChange">
                            </vxe-pager>
                        </template>
                </vxe-grid>
            </el-col>
        </el-row>
    </el-main>
        <el-dialog width="420px" :title="showEdit ? '编辑角色' : '新增角色'" :visible.sync="dialogFormVisible" center :close-on-click-modal="false">
            <el-form ref="roleForm" :model="roleForm" :rules="roleFormRules">
                    <el-form-item label="角色名称:" prop="name">
                        <el-input  size="mini" :readonly="!roleForm.is_able_change && showEdit" v-model="roleForm.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="remark" style=" margin-left: 40px; ">
                        <el-input  size="mini" v-model="roleForm.remark" autocomplete="off" style=" width: 67% !important; "></el-input>
                    </el-form-item>
                <el-form-item label="授权科目:" prop="role_subject" style=" margin-left: 40px; ">
                    <el-select
                            v-model="roleForm.role_subject"
                            collapse-tags
                            size="mini"
                            multiple
                            filterable
                            clearable
                            default-first-option
                            placeholder="可多选">
                        <el-option
                                v-for="item in roleSubjectList"
                                :key="item.id_subject"
                                :label="item.id_subject+'-'+item.subject_name"
                                :value="item.id_subject">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveRole('roleForm')">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>

    export default {

        data() {
            return {
                showEdit: false,
                roleSubjectList:[],
                roleForm: {
                    id:'',
                    name:'',
                    remark:'',
                    is_able_change:false,
                    role_subject:[]
               },
                dialogFormVisible: false,
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    keyword:''
               },
                //表单验证规则
                roleFormRules: {
                    name: [
                        {required: true, message: "请输入角色名称", trigger: "blur"},
                    ]
               },
                tableColumn: [
                    {field:'id', title: 'ID', width: 40},
                    {field:'name', width: 180 ,title: '名称'
                   },
                    {field:'remark', width: 280 ,title: '备注'},
                    {field:'subject_name', width: 280 ,title: '可查询科目',
                        formatter: ({row,cellValue}) => {
                            if(row.id == 1){
                                return '全部'
                           }else{
                                return cellValue
                           }
                       }
                   },
                    {field:'created', width: 180 ,title: '创建时间'},
                    {field:'is_able_change', width: 180 ,title: '角色名字能否修改',
                        titleHelp: {message: '角色名字固定不能修改，用于销售开单等页面下拉筛选！\n（无论是否，权限都可以正常修改设置）'},
                        formatter: ({cellValue}) => {
                            if(cellValue == true){
                                return '是'
                           }else{
                                return '否'
                           }
                       }
                   },
                    {field:'operate',title: '操作', width: 160, slots: {default: 'operate'}}
                ],
                tableDataMain : [],
           }
       },

        methods: {
            openAssigningRole(row){
                this.$router.push({path: '/assigningRole', query: {id: row.id}})

                //window.open('/AssigningRole?id='+row.id,'_blank');
           },
            addRole(){
                this.dialogFormVisible = true;
                this.showEdit=false;
                this.roleForm.id='';
                this.roleForm.name='';
                this.roleForm.remark='';
                this.getRoleSubjectByRoleId();
           },
            //获取所有二级科目
            getRoleSubject(){
                this.$axios({
                    method:'post',
                    url:'/admin/roleSubject',
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.roleSubjectList = response.data;
                   }
               }).catch((error) =>{
                    console.log(error)
               });

           },
            //获取不同角色的权限
            getRoleSubjectByRoleId(){
                this.$axios({
                    method:'post',
                    url:'/admin/roleSubject/getRoleSubjectByRoleId',
                    params: {
                        role_id:this.roleForm.id
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.roleForm.roleSubject = response.data;
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           },
            editRowEvent(row){
                this.showEdit = true;
                this.dialogFormVisible = true;
                this.roleForm.name ='';
                this.roleForm.is_able_change = row.is_able_change;
                this.roleForm.role_subject = row.role_subject
                //前端转数组
                if (!this.isBlankVue(row.role_subject) && row.role_subject != '[]'){
                    //[101,102,551]
                    //let arrList = row.role_subject.replaceAll('[','').replaceAll(']','').split(',');
                    let arrList = row.role_subject.replace("[", "").replace("]", "").split(',');
                    //console.log(arrList)
                    this.roleForm.role_subject = arrList;
               }else{
                    this.roleForm.role_subject = [];
               }
               this.$axios({
                    method:'post',
                    url:'/admin/role/editVue',
                    params: {
                        id:row.id
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.roleForm.name = response.data.role.name;
                        this.roleForm.remark =  response.data.role.remark;
                        this.roleForm.id =  response.data.role.id;
                        this.getRoleSubjectByRoleId();
                   }
               }).catch((error) =>{
                    console.log(error)
               });

           },
            syncRole(){
                this.$axios({
                    method:'post',
                    url:'/admin/permission/syncVue',
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            dangerouslyUseHTMLString: true,
                            showClose: true,
                            message: response.data.msg,
                            type: 'success'
                       });
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           },
            removeEvent (row) {
                this.$XModal.confirm('您确定要删除该数据?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method:'post',
                            url:'/admin/role/deleteVue',
                            params: {
                                id:row.id
                           }
                       }).then((response) =>{         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if(response.status === 200 && response.data.state == 'ok'){
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.getRoleListPageVue();
                           }else{
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) =>{
                            console.log(error)
                       });
                   }
               })
           },
            saveRole(formName){
                let url = '';
                if(this.showEdit){
                    url = '/admin/role/updateVue';
               }else{
                    url = '/admin/role/saveVue';
               }
                this.$refs[formName].validate((valid) => {
                    if(valid){
                        this.$axios({
                            method:'post',
                            url:url,
                            params: this.roleForm,
                       }).then((response) =>{         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if(response.status === 200 && response.data.state == 'ok'){
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                               });
                                this.dialogFormVisible = false;
                                this.getRoleListPageVue();
                           }else{
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) =>{
                            console.log(error)
                       });
                   }else {
                    //console.log('error submit!!');
                    return false;
                   }
               });
           },
            handlePageChange ({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getRoleListPageVue()
           },
            //查询accountList
            async getRoleListPageVue(){
                this.$axios({
                    method:'post',
                    url:'/admin/role/getRoleListPageVue',
                    params:this.tablePage
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           }
       },
        created () {
            this.getRoleListPageVue();
            this.getRoleSubject();
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 60% !important;
   }
    /deep/ .el-form-item__error{
        position: initial !important;
   }
    .el-tag + .el-tag {
        margin-left: 10px;
        margin-top: 10px;
   }
</style>
